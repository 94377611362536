<template>
  <div class="regsit">
    <left-img :target="'/regist_type'"></left-img>
    <el-form label-width="80px" :model="regist_form" :rules="regist_rules" ref="form_ref">
      <div class="regist_header">注册</div>
      <el-form-item label="用户名" prop="uname">
        <!--<el-input id="bgColor" v-model="regist_form.username" placeholder="请输入用户名" @blur="check_name"></el-input> -->
        <el-input id="bgColor" v-model="regist_form.uname" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="upwd">
        <el-input id="bgColor" v-model="regist_form.upwd" placeholder="请输入密码" type="password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="upwd2">
        <el-input id="bgColor" v-model="regist_form.upwd2" placeholder="请确认密码" type="password" @blur="verification_uowd"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="phone">
        <el-input id="bgColor" placeholder="请输入电话" v-model="regist_form.phone"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="code" class="img_code_input">
        <el-input id="bgColor" placeholder="请输入验证码" v-model="regist_form.code"></el-input>
        <div class="imgCode">
          <el-button v-show="dis == false" @click="get_code" type="primary">获取验证码</el-button>
          <el-button v-show="dis == true" :disabled="dis">
            {{codeText}}
          </el-button>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="regist" :disabled="check">立即注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import leftImg from './left_img.vue'
  //密码加密
  // 手机号的正则
  var ph = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/
  export default {
    name: 'register',
    data() {
      return {
        codeText: '获取验证码',
        time: 60,
        yangshi: '',
        dis: false,
        // 图形验证码

        // 绑定
        regist_form: {
          uname: '',
          upwd: '',
          upwd2: '',
          phone: '',
          code: ''
        },
        // 类型
        type: this.$route.query.type,
        // 用户名注册
        check: false,
        // 验证规则
        regist_rules: {
          uname: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            {
              min: 6,
              max: 15,
              message: '长度在 6 到 15 个字符',
              trigger: 'blur'
            }
          ],
          upwd: [
            { required: true, message: '请输入密码', trigger: 'blur'},
            { pattern:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,message:'密码必须包含字母与数字',trigger: 'blur'},
            {
              min: 6,
              max: 20,
              message: '长度在 6 到 20 个字符',
              trigger: 'blur'
            }
          ],
          upwd2: [
            { required: true, message: '请输入确认密码', trigger: 'blur' },
            {
              min: 6,
              max: 20,
              message: '长度在 6 到 20 个字符',
              trigger: 'blur'
            }
          ],
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            {
              min: 6,
              max: 20,
              message: '长度在 6 到 20 个字符',
              trigger: 'blur'
            }
          ],

          code: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
            {
              min: 5,
              max: 6,
              message: '长度在 5 到 6 个字符',
              trigger: 'blur'
            }
          ]
        },
        // uuid
        uuid: '',
        // 图形验证码
        info: ''
      }
    },
    components: {
      leftImg
    },
    mounted() {
      this.uuid = Date.now() + 'uuid' + parseInt(Math.random() * 1000)
    },
    methods: {
      toBack() {
        this.$router.push('/regist_type')
      },
      verification_uowd() {
        if (this.regist_form.upwd != this.regist_form.upwd2) {
          this.$message({
            type: 'error',
            message: '两次密码不一致'
          })
          this.regist_form.upwd2 = ''
        }
      },
      ids() {
        window.localStorage.clear()
        this.$axios('/get_svg', {
          params: {
            uuid: this.uuid
          }
        }).then(res => {
          this.info = res.data
        })
      },
      // 倒计时
      timeout() {
        let that = this
        that.codeText = `${that.time}s后重新发送`
        that.time--
        that.dis = true
        if (that.time == 0) {
          that.time = 60
          that.dis = false
          return
        }
        setTimeout(function () {
          that.timeout()
        }, 1000)
      },
      // 验证码
      get_code() {
        let that = this
        if (ph.test(this.regist_form.phone)) {
          this.dis = true
          this.$axios
            .post('/verificationCode', {
              phone: this.regist_form.phone,
            })
            .then(res => {
              if (res.code == 201) {
                that.timeout()
              }else{
                this.dis = false
              }
            })
        } else {
          this.$message({
            type: 'error',
            message: '手机号格式错误'
          })
          this.regist_form.phone = ''
        }
      },
      // 注册
      regist() {
        this.regist_form.type = this.$route.query.type
        this.$refs.form_ref.validate(valid => {
          if (valid) {
            // 验证验证码是否正确
            this.$axios({
              url: '/insert',
              method: 'post',
              data: this.regist_form
            }).then(res => {
              if (res.code == 200) {
                window.localStorage.setItem('user_id', res.data.user_id)
                this.$message({
                  type: 'success',
                  message: res.msg
                })
                this.$confirm('注册完成，是否继续填写认证信息', '温馨提示', {
                  confirmButtonText: '去认证',
                  cancelButtonText: '算了，下次',
                  type: 'success'
                })
                  .then(() => {
                    if (this.regist_form.type == 1) {
                      this.$router.push('/j_regist')
                    } else {
                      this.$router.push('/q_regist')
                    }
                  })
                  .catch(() => {
                    this.$router.push('/login')
                  })
              }
            })
          }
        })
      }
    }
  }
</script>
<style scoped>
  .regsit {
    height: 100%;
    background: url('../../public/images/bssjx.png') no-repeat center;
  }

  .el-form {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-10%);
    width: 30%;
    background-color: #fff;
    padding: 50px 20px;
  }

  .el-form-item {
    margin-top: 40px;
  }

  .regist_header {
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .el-input {
    width: 90%;
  }

  #bgColor:focus {
    border: 2px solid rgb(40, 56, 82);
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px rgba(40, 56, 82, 0.15);
  }

  .info {
    position: absolute;
    top: 2px;
    right: 20px;
  }

  .img_code_input .el-input {
    width: 60%;
  }

  .imgCode {
    display: inline-block;
    width: 30%;
    text-align: center;
  }

  .imgCode .el-button {
    margin-left: 20px;
  }

  @media screen and (max-width: 1250px) {
    .el-form {
      left: 30%;
      top: 10%;
      width: 450px;
    }
  }

  @media screen and (max-width: 550px) {
    .el-form {
      width: 80%;
      margin-bottom: 50px;
      top: 5%;
      left: 10%;
    }
    .el-form-item {
      margin-top: 30px;
    }
    .imgCode .el-button {
      margin-left: 2px;
    }
    .el-button {
      padding: 8px 8px;
      font-size: 12px;
    }
  }
</style>