<template>
  <div>
    <div class="left_img">
      <h1>警方与企业的信息交互平台</h1>
      <p>一个为互联网企业提供更加有效的信息交换系统</p>
      <img src="../../public/images/banner.png" alt />
    </div>
    <div class="toBack" @click="toBack()">
      <a href="#">
        <img src="../../public/images/toBack.png" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    target: {}
  },
  data() {
    return {}
  },
  methods: {
    toBack() {
      this.$router.push(this.target)
    }
  }
}
</script>

<style scoped>
.left_img {
  width: 560px;
  height: 100vh;
  position: absolute;
  display: flex;
  background: #071f46;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  color: #ccc;
}
.left_img img {
  margin-top: 90px;
}
.left_img h1 {
  font-size: 28px;
  color: #fff;
  margin-top: -100px;
}
.toBack {
  position: absolute;
  left: 630px;
  top: 5%;
}

@media screen and (max-width: 1660px) {
  .left_img {
    display: none;
  }
  .toBack {
    left: 5%;
    z-index: 99;
  }
}
</style>